<template>
  <div>
    <div class="wet-section">
      <div class="container">
        <p class="empty-ph wet-note">{{ strings.buy && strings.buy.description }}</p>
        <h2 class="empty-ph text-dark">{{ strings.buy && strings.buy.offer }}</h2>
        <template v-if="apiData.insurer">
          <div class="empty-ph wet-note">{{ strings.buy && strings.buy.insurer_name }}</div>
          <p class="empty-ph-small">{{ apiData.insurer && apiData.insurer.name }}</p>
        </template>
        <div class="empty-ph wet-note">{{ strings.buy && strings.buy.business_type_title }}</div>
        <p class="empty-ph-small">{{ apiData.order && apiData.order.business.branch_name }}</p>
        <div class="empty-ph wet-note ">{{ strings.buy && strings.buy.business_address }}</div>
        <p class="empty-ph mb-0">{{ stringsf.business_address }}</p>
        <p class="empty-ph">{{ stringsf.coordinates }}</p>
        <div class="empty-ph wet-note ">{{ strings.buy && strings.buy.address_station }}</div>
        <p class="empty-ph">{{ stringsf.address_station }}</p>
        <!-- insured_weather -->
        <h2 class="empty-ph text-dark">{{ strings.buy && strings.buy.insured_weather }}</h2>
        <p class="empty-ph">{{ stringsf.insured_weather }}</p>
        <div class="empty-ph wet-note ">{{ strings.buy && strings.buy.period }}</div>
        <p class="empty-ph">{{ stringsf.period }}</p>
        <p
          v-if="apiData.order && apiData.order.weather.direction === availableWeatherDirs.rainy"
          class="empty-ph mt-n3"
        >{{ stringsf.times }}</p>
        <p class="mt-n2 small empty-ph">{{ stringsf.measuring }}</p>
        <div class="empty-ph wet-note mt-3">{{ strings.buy && strings.buy.deductible_days }}</div>
        <p class="empty-ph-small">{{ stringsf.riskDays }}</p>
        <div class="empty-ph wet-note ">{{ strings.buy && strings.buy.payout_per_day }}</div>
        <p>
          <money-field
            :value="apiData.order && apiData.order.daily_payout"
            :dynamic-cents="true"
            :currency="currency"
          />
        </p>
        <div class="empty-ph wet-note ">{{ strings.buy && strings.buy.maximum_payout }}</div>
        <p>
          <money-field
            :value="apiData.order && apiData.order.reduction"
            :dynamic-cents="true"
            :currency="currency"
          />
        </p>
        <div class="dropdown-divider my-3 my-md-4"></div>
        <!-- Business information -->
        <h2 class="empty-ph text-dark">
          {{ strings.buy && strings.buy.business_information_title }}
        </h2>
        <div class="empty-ph wet-note ">{{ strings.buy && strings.buy.company_name }}</div>
        <p class="empty-ph">{{ apiData.profile && apiData.profile.company }}</p>
        <div class="empty-ph wet-note ">{{ strings.buy && strings.buy.registration_address }}</div>
        <p class="empty-ph">{{ stringsf.registration_address }}</p>
        <div class="empty-ph wet-note ">{{ strings.buy && strings.buy.contact_person }}</div>
        <p class="empty-ph">{{ stringsf.contact_person }}</p>
        <div class="empty-ph wet-note ">{{ strings.buy && strings.buy.payment }}</div>
        <p class="empty-ph-small">{{ apiData.order && apiData.order.iban }}</p>
        <div class="dropdown-divider my-3 my-md-4"></div>
        <!-- Documents download -->
        <h2 class="empty-ph text-dark">{{ strings.buy && strings.buy.documents_download }}</h2>
        <div class="empty-ph wet-note ">
          {{ strings.buy && strings.buy.documents_download_description }}
        </div>
        <div
          v-for="(link, i) in (apiData.order ? apiData.order.documents.files : [])"
          :key="i"
          class="text-secondary font-weight-bold my-3"
        >
          <a
            :id="`buy-document-link-${i + 1}`"
            :title="link.filename"
            :href="link.url"
            target="_blank"
          >{{ link.name }}</a>
        </div>
        <a
          :id="`wet-buy-download-all`"
          :title="strings.buy && strings.buy.download_all || ''"
          :href="apiData.order ? apiData.order.documents.all.url : ''"
          download
        >
          <button
            class="wet-button wet-button-outlined empty-ph"
            style="min-width:10rem;"
            @click="allowDownloadCheckbox"
          ><slot>{{ strings.buy && strings.buy.download_all || '' }}</slot></button>
        </a>
      </div>
    </div>
    <div class="wet-section wet-shadow-inner">
      <div class="container">
        <div class="d-flex justify-content-between align-items-baseline font-weight-bold mb-3">
          <div>{{ strings.buy && strings.buy.summary }}</div>
          <div class="text-right">
            {{ stringsCommonApp.price_including_taxes}}:
            <money-field
              :value="apiData.order && apiData.order.price"
              size="lg"
              :currency="currency"
            />
          </div>
        </div>
        <label
          id="wet-buy-chkbx1-label"
          class="checkbox is-size-7 my-3"
          :class="{
            'disabled': checkbox1Disabled,
          }"
          for="wet-buy-chkbx1"
          :title="checkbox1Disabled ? 'Please, press &quot;Download&quot; all button' : ''"
        >
          <strong class="empty-ph">
            {{ strings.buy && strings.buy.checkbox_1 }}
          </strong>
          <input
            type="checkbox"
            id="wet-buy-chkbx1"
            class=""
            v-model="form.checkbox1"
            :disabled="checkbox1Disabled"
          >
          <span id="wet-buy-chkbx1-span"></span>
        </label>
        <label
          id="wet-buy-chkbx2-label"
          class="checkbox is-size-7 my-3"
          for="wet-buy-chkbx2"
        >
          <strong class="empty-ph">
            {{ strings.buy && insurerId && strings.buy.checkbox_2[insurerId] }}
          </strong>
          <input
            type="checkbox"
            id="wet-buy-chkbx2"
            class=""
            v-model="form.checkbox2"
          >
          <span id="wet-buy-chkbx2-span"></span>
        </label>
        <label
          id="wet-buy-chkbx3-label"
          class="checkbox is-size-7 my-3"
          for="wet-buy-chkbx3"
        >
          <strong class="empty-ph">
            {{ strings.buy && insurerId && strings.buy.checkbox_3[insurerId] }}
          </strong>
          <input
            type="checkbox"
            id="wet-buy-chkbx3"
            class=""
            v-model="form.checkbox3"
          >
          <span id="wet-buy-chkbx3-span"></span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
import common from '@/mixins/common';
import done from '@/mixins/done';
import { mapState } from 'vuex';
import { periodHumanize, hoursHumanize } from '@/common/snippets';
import wpSection from '@/enums/wpSection';
import { errorStrings } from '@/constants';

export default {
  mixins: [common, done],
  components: {
    moneyField: () => import(/* webpackChunkName: "moneyField" */ '@/components/ui/moneyFieldUi.vue'),
  },
  data() {
    return {
      buyInfoApi: '/api/user/info_buy',
      getQuoteApi: '/api/user/download/quote/',
      priceApi: '/api/order/price',
      apiData: {},
      progress: [5, 5],
      form: {
        checkbox1: false,
        checkbox2: false,
        checkbox3: false,
      },
      checkbox1Disabled: true,
      blobFile: null, /** container for downloaded file */
      maxLimitSecond: 20, /** max time period for check link, sec */
      insurerId: null, /** insurer id */
      missingStreet: process.env.VUE_APP_MISSING_STREET,
    };
  },
  computed: {
    ...mapState(['strings', 'lang', 'token']),
    stringsCommonApp() {
      return this.strings.commonApp || {};
    },
    countryTitles() {
      return this.stringsCommonApp.countries?.reduce((acc, cur) => {
        acc[cur.code] = cur.title;
        return acc;
      }, {}) || {};
    },
    stringsf() {
      const rloc = this.apiData.order?.location || {};
      const business_address = rloc.address;
      const sloc = this.apiData.order?.station;
      const address_station = sloc && this.strings.buy && `${sloc.name} \
        (${this.strings.buy?.no} ${sloc.id}, \
        ${this.strings.buy?.gps_coordinates} \
        ${sloc.latitude}, ${sloc.longitude}), \
        ${sloc.distance} ${this.strings.buy?.station_text}`;

      const iwParameter = this.stringsCommonApp.weather_type?.[this.apiData.order?.weather?.type];
      const iwOperator = [
        this.availableWeatherDirs.cold,
        this.availableWeatherDirs.nosnow,
        this.availableWeatherDirs.norain,
      ].includes(this.apiData.order?.weather?.direction)
        ? this.stringsCommonApp.less_than_or_equal
        : this.stringsCommonApp.more_than;
      const iwUnit = this.stringsCommonApp.unit?.[this.apiData.order?.weather?.type];
      const insured_weather = this.format(
        this.strings.buy?.insured_weather_subtitle,
        iwParameter,
        iwOperator,
        this.apiData.order?.weather?.threshold,
        iwUnit,
      );
      const period = this.periodHumanize(this, this.apiData.order?.dates);
      const times = this.format(
        this.strings.buy?.between_hours,
        this.hoursHumanize(this.apiData.order?.hours),
      );
      const bloc = this.apiData.profile?.registration_address;
      const bCountry = this.countryTitles[bloc?.country_code?.toLowerCase()] || bloc?.country_code;
      const registration_address = bloc && [
        bloc.address_line_1,
        bloc.address_line_2,
        bloc.address_line_city,
        bCountry].join(', ');
      const contact_person = (this.apiData.profile ? [
        this.apiData.profile.firstname,
        this.apiData.profile.lastname,
        `(${this.apiData.profile.phone_code})${this.apiData.profile.phone}`,
      ] : []).join(' ');
      /** deductible days */
      const riskDays = this.apiData.order?.risk_days;
      let measuring = '';
      switch (this.apiData.product?.weather_direction) {
      case this.availableWeatherDirs.rainy:
      case this.availableWeatherDirs.norain:
        measuring = this.apiData.product?.period === this.availableProductPeriod.daily
          ? this.strings.buy.measuring_rain_daily
          : this.strings.buy.measuring_rain_hourly;
        break;
      case this.availableWeatherDirs.heat:
      case this.availableWeatherDirs.cold:
        measuring = this.strings.buy.measuring_temperature_daily;
        break;
      case this.availableWeatherDirs.snowy:
      case this.availableWeatherDirs.nosnow:
        measuring = this.strings.buy.measuring_snow_daily;
        break;
      default:
        break;
      }
      return {
        business_address,
        coordinates: `${this.strings.buy?.gps_coordinates} \
          ${this.apiData.order?.location?.latitude}, \
          ${this.apiData.order?.location?.longitude}`,
        address_station,
        insured_weather,
        period,
        times,
        registration_address,
        contact_person,
        riskDays,
        measuring,
      };
    },
    currency() {
      return this.apiData.order?.currency;
    },
  },
  watch: {
    form: {
      deep: true,
      immediate: true,
      handler() {
        this.$emit('update:nextDisabled', Object.values(this.form).some((chkbx) => !chkbx));
      },
    },
  },
  async created() {
    await Promise.all([
      this.getWordPressStrings([wpSection.BUY, wpSection.COMMON_APP], wpSection.BUY),
      this.$store.commit('SET_APP_LAYOUT', { progress: this.progress }),
      this.$root.$on('buttonNext', this.next),
      this.$root.$on('buttonBack', this.back),
      this.removeMagicCode(),
      this.getApiData(),
    ]);
  },
  destroyed() {
    // unwatch next & back buttons click watcher
    this.$root.$off('buttonNext', this.next);
    this.$root.$off('buttonBack', this.back);
  },
  methods: {
    hoursHumanize,
    periodHumanize,
    allowDownloadCheckbox() {
      this.checkbox1Disabled = false;
    },
    async getApiData() {
      // call api
      await this.callApi(this.buyInfoApi)
        .then((res) => {
          this.apiData = res?.data || {};

          /** set insurer id */
          this.insurerId = res?.data?.insurer?.id;

          // store
          this.$store.commit('SET_ORDER', this.apiData.order || {});
        });
    },
    async callPriceApi() {
      // call price api
      const data = {
        daily_payout: this.apiData.order.daily_payout,
        risk_days: this.apiData.order.risk_days,
        reduction: this.apiData.order.reduction,
      };

      if (['custom', null, undefined].includes(this.apiData.order.weather.severity)) {
        data.threshold = this.apiData.order.weather.threshold;
      } else {
        data.weather_severity = this.apiData.order.weather.severity;
      }

      const res = await this.callApi({ url: this.priceApi, method: 'post', data });

      if (Math.abs(res.price - this.apiData.order.price) > 0.00001) {
        // open modal
        const modal = {
          id: 'wet-price-info-modal',
          content: `<div class="text-center">${this.strings.buy?.price_changed}</div>`,
          close: this.closeInfo,
          actions: [
            {
              name: 'ok-price-info',
              title: this.stringsCommonApp.ok,
              event: ['close', this.closeInfo],
            },
          ],
        };
        this.$store.dispatch('addModal', modal);
        Promise.reject(new Error('price changed'));
      }
    },
    closeInfo() {
      // when close info modal - redirect back to done page
      this.$router.push({ name: 'done', params: { ...this.$route.params } });
    },
    async next() {
      // next button handler
      try {
        await this.callPriceApi();
        await this.$webAppAPI.postBuy();
        this.$router.push({ name: 'success', params: { ...this.$route.params } });
      } catch (error) {
        if (error.message === errorStrings.productNotActive) {
          const modal = {
            id: 'wet-buy-product-not-supported',
            content: `<div class="text-center">${this.strings.buy.error_product_not_active}</div>`,
            actions: [
              {
                name: 'wet-buy-product-not-supported',
                title: this.strings.commonApp?.ok,
                event: 'close',
              },
            ],
            close: this.onCloseModal,
          };
          this.$store.dispatch('addModal', modal);
        }
      }
    },
    back() {
      // back button handler
      this.$router.push({ name: 'additional', params: { ...this.$route.params } });
    },
    /**
     * remove magic_code from Cookies
     */
    removeMagicCode() {
      const magicCode = this.$Cookies.get('magic_code');
      if (magicCode) this.$Cookies.remove('magic_code', { secure: true, sameSite: 'None', expires: 365 });
    },
  },
};
</script>
<style lang="scss">
  .wet-shadow-inner {
    box-shadow: inset 0 1rem 1rem -1rem rgba(0,0,0,.2);
  }
</style>
